<template>  
    <!-- Mobile Header -->
    <header>
      <button v-on:click="showMobileMenu = !showMobileMenu" v-bind:class="{ open: showMobileMenu }" class="menu"><span></span><span></span><span></span></button>
      <ul class="header-title">
          <li v-show="step == 1 || step == 2">Zusatzoptionen</li>
          <li v-show="step == 3">Scheidungsantrag</li>
          <li v-show="step == 4">Zustimmung</li>
          <li v-show="step == 5">Daten 1</li>
          <li v-show="step == 6">Daten 2</li>
          <li v-show="step == 7">Heirat & Trennung</li>
          <li v-show="step == 8">Wohnung</li>
          <li v-show="step == 9 || step == 10">Kinder</li>
          <li v-show="step >= 11">Kontaktdaten</li>
      </ul><a class="whatsapp-header" @click="showHotlineModal(!modalHotline)" href="#mobile-phone">
          <svg>
          <use xlink:href="@/assets/images/sprite.svg#icons8-ringer-volume"></use>
          </svg></a>
      <!-- Mobile menu-->
      <ul v-bind:class="{ open: showMobileMenu }" class="mobile-nav">
          <li><router-link to="/">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-external-link"></use>
              </svg>STARTSEITE</router-link></li>
          <li><router-link to="/impressum">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-external-link"></use>
              </svg>IMPRESSUM</router-link></li>
          <li><router-link to="/datenschutz">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-external-link"></use>
              </svg>DATENSCHUTZ</router-link></li>
          <li><router-link to="/widerruf">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-external-link"></use>
              </svg>WIDERRUF</router-link></li>
      </ul>
    </header>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'HeaderMobile',
  data() {
    return {
        showMobileMenu: false
    };
  },
  methods: {
    ...mapMutations({
        showHotlineModal: 'setModalHotline',
      }),
  },
  computed: mapState({
    step: (state) => state.step,
    user: (state) => state.user,
    modalHotline: (state) => state.modalHotline
  }),
}
</script>